import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BlogComponent from '../components/blogs';
import { useParams } from 'react-router-dom';
import { JSON_FILE_PATH } from '../common/constants';

import Header from '../components/header';
import Footer from '../components/footer';

export const BlogPage = () => {
	const { blogId } = useParams();
	const [blogFile, setBlogFile] = useState(null);
	useEffect(() => {
		// Fetch the blogs data from the JSON file in the public folder
		fetch(JSON_FILE_PATH)
			.then((response) => {
				console.log('response', response);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				console.log('FETCHED DATA');
				console.log(data);
				if (data && Array.isArray(data.blogs)) {
					const blog_detail = data.blogs.filter(
						(b) => b.id === blogId
					);
					console.log('blog_detail');
					console.log(blog_detail[0]);
					setBlogFile(blog_detail[0].file);

					// Set the document title
					document.title = blog_detail[0].title;
				} else {
					console.log('ERROR', data);
					throw new Error('Invalid data format');
				}
			});
	}, [blogId]);
	return (
		<>{blogFile ? <BlogComponent blogFile={blogFile} /> : 'Loading....'}</>
	);
};

const BlogItemComponent = ({ title, content }) => {
	const blogUrl = `/blog/${content}`;

	return (
		<div className="max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl mx-auto border border-gray-200 rounded-lg shadow-md overflow-hidden m-4 transition-transform transform hover:scale-105 hover:shadow-lg">
			<Link
				to={blogUrl}
				className="block p-4 text-gray-900 hover:text-blue-600"
			>
				<h2 className="text-xl font-semibold mb-2">{title}</h2>
			</Link>
		</div>
	);
};

const BlogListComponent = () => {
	const [blogsList, setBlogsList] = useState([]);
	useEffect(() => {
		// Fetch the blogs data from the JSON file in the public folder
		fetch(JSON_FILE_PATH)
			.then((response) => {
				console.log('response', response);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.json();
			})
			.then((data) => {
				setBlogsList(data.blogs);
			});
	}, []);

	return (
		<div className="min-h-screen lg:w-[1007px] mx-auto py-8 px-3 bg-white mt-16">
			<h2>Latest Blogs</h2>
			{blogsList ? (
				blogsList.length > 0 ? (
					blogsList.map((post) => (
						<BlogItemComponent
							key={post.id}
							title={post.title}
							content={post.id}
						/>
					))
				) : (
					<p>No blog posts available.</p> // Message for empty blog list
				)
			) : (
				<p>Loading...</p> // Loading indicator while data is being fetched
			)}
		</div>
	);
};

export const BlogListPage = () => {
	return (
		<>
			<Header />
			<BlogListComponent />
			<Footer />
		</>
	);
};
