// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';

import HomePage from './routes/home';
import JobPosts from './routes/jobs';
import ErrorPage from './routes/error-page';
import RegistrationPage from './routes/registration';
import JobDetailPage from './routes/jobDetailsPage';
import { BlogListPage, BlogPage } from './routes/blog-page';
import './tailwind.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

export default function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/jobs" element={<JobPosts />} />
				<Route path="/jobs/:jobId" element={<JobDetailPage />} />
				<Route path="/apply" element={<RegistrationPage />} />
				<Route path="/404-not-found" element={<ErrorPage />} />
				<Route path="*" element={<Navigate to="/404-not-found" />} />
				<Route path="/blogs" element={<BlogListPage />} />
				<Route path="/blog/:blogId" element={<BlogPage />} />
			</Routes>
		</BrowserRouter>
	);
}
