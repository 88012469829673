import { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const BlogComponent = ({ blogFile = null }) => {
	const [blogContent, setBlogContent] = useState({});

	useEffect(() => {
		if (blogFile) {
			const html_file_path = '/blogs_repo/html/' + blogFile;
			console.log('html_file_path', html_file_path);
			fetch(html_file_path)
				.then((response) => {
					console.log('REading HTML File');
					console.log(response);
					return response.text();
				})
				.then((html) => {
					console.log('html below');
					console.log(html);
					setBlogContent(html);
				})
				.catch((error) => console.error('Error loading blog:', error));
		}
	}, [blogFile]);
	console.log('BlogComponent', blogFile);
	return (
		<div>
			<Header />
			<div className="lg:w-[1007px] mx-auto w-full mt-20">
				<div dangerouslySetInnerHTML={{ __html: blogContent }} />
			</div>
			<Footer />
		</div>
	);
};

export default BlogComponent;
